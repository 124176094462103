<template>
  <item-edit-view
    title="Редактировать файл"
    :loading="loading"
  >
    <template v-slot:bottom>
      <router-link :to="{ name: 'Files' }">
        <v-icon small left>mdi-arrow-left</v-icon>
        <span>К списку файлов</span>
      </router-link>
    </template>
    <template v-slot:right>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="save"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>

    <v-card v-if="item">
      <v-card-title class="mb-2 pb-0">
        {{item.originalFileName}}
      </v-card-title>

      <v-card-text>
        <code
          style="cursor: pointer;"
          class="pb-1 pt-1 pr-1 pl-1"
          v-clipboard:copy="item.url"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
        >
          {{item.url}}
          <v-icon x-small class="ml-1">fa-copy</v-icon>
        </code>

        <v-text-field
          class="mt-4"
          v-model="item.title"
          label="Название"
        ></v-text-field>

        <div class="subtitle mt-4">
          Дата создания
        </div>
        <div>
          {{ item.createdAt | dateTime }}
        </div>

      </v-card-text>
    </v-card>
  </item-edit-view>
</template>


<script>
import fileService from '../../services/files'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'

export default {
  mixins: [createOrUpdateViewMixin(fileService)],
  methods: {
    onCopy(){
      this.$notify.success({
                title: 'Скопировано в буфер обмена'
        });
    },
    onCopyError(){
      this.$notify.error({
                title: 'Не удалось скопировать'
        });
    }
  }
}
</script>
